import React, { useState, useEffect }  from "react";

// react-bootstrap components
import { Button, Row, Col } from "react-bootstrap";
import LayerSelector3D from "./LayerSelector3D";
// arcgis
import { WebScene } from "@esri/react-arcgis";
import { setDefaultOptions } from "esri-loader";
import Setting from "./arcgis/Setting";
import SavePlugin from "./arcgis/SavePlugin";
import Plugin from "./arcgis/Plugin";
import ShapeLayer3D from "./arcgis/ShapeLayer3D";

import Split from 'react-split'
import { pickColor } from "./arcgis/color";

import { useI18n } from "../../context/i18n";

import Cookies from "universal-cookie";
import useSWR from "swr";
import { BACKEND_API } from "config";
import "../../assets/css/split.css";
const cookies = new Cookies();
const axios = require("axios").default;

const fetcher = (url) =>
  axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + cookies.get("sdgs_access_token"),
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);

const SettedLayerSelector3D = ({
  setlayer3DSelected,
  setHiddenDataList,
  hiddenDataList

}) => {
  const { i18n } = useI18n();
  setDefaultOptions({ css: true });

  const [layer3DCollection, setlayer3DCollection] = React.useState([]);
  const onDelete = () => {
    window.location.reload();
  };
  React.useEffect(() => {
  fetcher(`${BACKEND_API}/api/file3d/layer/get`).then(setlayer3DCollection);
  }, []);
  return <>
  <LayerSelector3D
        id={1}
        icon="nc-icon nc-puzzle-10"
        title={i18n.strings.dashboard.arcGisViewer3D.layer}
        backgroundColor="primary"
        options={layer3DCollection}
        onSelect={setlayer3DSelected}
        hiddenChildrenList={hiddenDataList}
        keyValue="shapefile-id"
        fileType="file3D"
        parentType="data"
        childrenType="data"
        onHide={(id) =>
          setHiddenDataList((prevList) => {
            if (prevList.includes(id)) {
              prevList.splice(prevList.indexOf(id), 1);
              return [...prevList];
            }
            return [...prevList, id];
          })
        }
        onDelete={onDelete}
      />
  </>
}

const SettedWebScene = ({
  layer3DSelected,
  hiddenDataList,
  setlayer3DSelected
}) => {
  const { i18n } = useI18n();
  setDefaultOptions({ css: true });

  const [layer3DCollection, setlayer3DCollection] = React.useState([]);
  React.useEffect(() => {
  fetcher(`${BACKEND_API}/api/file3d/layer/get`).then(setlayer3DCollection);
  }, []);

  return <><WebScene
  mapProperties={{ basemap: "osm" }}
  viewProperties={{
    center: [121.53897799930016, 25.017766628321343],
    zoom: 16,
    popup: {
      defaultPopupTemplateEnabled: true,
    },
  }}
  style={{
    position: "relative",
    height: "80vh",
  }}
>
  {layer3DCollection  ? (
    layer3DCollection.map((data, index) => ( <ShapeLayer3D
    key={index}
    disabled={
      layer3DSelected.findIndex((elem) => elem.value === data.id) ===
        -1 
    }
    info={data}
    dataList={layer3DCollection.filter(
      (elem) => elem["shapefile-id"] === data.id
    )}
    hiddenDataList={hiddenDataList}
    color={pickColor(index)}
  /> ))
  ) : (
    <></>
  )}
</WebScene>
</>
}


function Arc3DGISViewer(){
  const { i18n } = useI18n();
  setDefaultOptions({ css: true });
  const [isResponsive, setResponsive] = useState(false);
  const [layer3DSelected, setlayer3DSelected] = React.useState([]);
  const [hiddenDataList, setHiddenDataList] = React.useState([]);
  const divStyle = {
    padding: '20px',
    width: '20%',
  };

  useEffect(() => {
    const handleResize = () => {
      // 根据屏幕宽度触发切换到响应式布局
      if (window.innerWidth > 768) {
        setResponsive(true);
      } else {
        setResponsive(false);
      }
    };
  
    // 监听窗口大小变化
    window.addEventListener('resize', handleResize);
  
    // 初始加载时检查屏幕宽度
    handleResize();
  
    // 在组件卸载时取消监听
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Row>
      {isResponsive ? (
      <Col xl="12" md="12">
      <Split
          className="split"
          sizes={[25, 75]}
      >
        <div style={divStyle}> 
          <br></br>
          {<SettedLayerSelector3D
            setlayer3DSelected = {setlayer3DSelected}
            setHiddenDataList = {setHiddenDataList}
            hiddenDataList = {hiddenDataList}
          />}
          <p></p>
        </div>
        <div>
          <SettedWebScene
          layer3DSelected = {layer3DSelected}
          hiddenDataList ={hiddenDataList}
          setlayer3DSelected = {setlayer3DSelected}
          />
        </div>
      </Split>
      </Col>
      ): ( <>
      <Col xl="2" md="12">
        <br></br>
        {<SettedLayerSelector3D
            setlayer3DSelected = {setlayer3DSelected}
            setHiddenDataList = {setHiddenDataList}
            hiddenDataList = {hiddenDataList}
          />}
        <p></p>
      </Col>
      <Col xl="10" md="12">
      <SettedWebScene
          layer3DSelected = {layer3DSelected}
          hiddenDataList ={hiddenDataList}
          setlayer3DSelected = {setlayer3DSelected}
          />
      </Col></>)}
    </Row>
  );
};

export default Arc3DGISViewer;
