import React from "react";

// react-bootstrap components
import { Button, Row, Col } from "react-bootstrap";
import LayerSelector from "./LayerSelector";

// arcgis
import { WebMap } from "@esri/react-arcgis";
import { setDefaultOptions } from "esri-loader";
import Setting from "./arcgis/Setting";
import SavePlugin from "./arcgis/SavePlugin";
import Plugin from "./arcgis/Plugin";
import ShapeLayer from "./arcgis/ShapeLayer";
import PointLayer from "./arcgis/PointLayer";
import LineLayer from "./arcgis/LineLayer";
import SensorLayer from "./arcgis/SensorLayer";
import LayerRenderer from "./arcgis/LayerRenderer";

import { pickColor } from "./arcgis/color";

import { useI18n } from "../../context/i18n";
import { useAlert } from "context/Alert";
import Cookies from "universal-cookie";
import useSWR from "swr";
import { BACKEND_API } from "config";
const cookies = new Cookies();
const axios = require("axios").default;

const fetcher = (url) =>
  axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + cookies.get("sdgs_access_token"),
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);

const ArcGISViewer = () => {
  const { i18n } = useI18n();
  const { pop } = useAlert();
  setDefaultOptions({ css: true });
  // 看要不要改在後端限制
  const layerLimit = 7;
  const [shpCollection, setShpCollection] = React.useState([]);
  const [shpDataCollection, setShpDataCollection] = React.useState([]);
  const [lineFileCollection, setLineFileCollection] = React.useState([]);
  const [lineDataCollection, setLineDataCollection] = React.useState([]);
  const [pointFileCollection, setPointFileCollection] = React.useState([]);
  const [pointDataCollection, setPointDataCollection] = React.useState([]);
  const [sensorCollection, setSensorCollection] = React.useState([]);


  const useLayerState = (initialState) => {
    const [layerFileIdx, setLayerFileIdx] = React.useState(initialState);
    const [layerCounter, setLayerCounter] = React.useState(initialState);
    
    return [layerFileIdx, setLayerFileIdx, layerCounter, setLayerCounter];
  };
  
  const [shpLayerFileIdx, setShpLayerFileIdx, shpLayerCounter, setShpLayerCounter] = useLayerState({});
  const [lineLayerFileIdx, setLineLayerFileIdx, lineLayerCounter, setLineLayerCounter] = useLayerState({});
  const [pointLayerFileIdx, setPointLayerFileIdx, pointLayerCounter, setPointLayerCounter] = useLayerState({});
  
  React.useEffect(() => {
    fetcher(`${BACKEND_API}/api/shp/file/list`).then(setShpCollection);
    fetcher(`${BACKEND_API}/api/shp/data/list`).then((setShpDataCollection))
    fetcher(`${BACKEND_API}/api/line/data/list`).then((setLineDataCollection))
    fetcher(`${BACKEND_API}/api/point/data/list`).then((setPointDataCollection))
    fetcher(`${BACKEND_API}/api/line/file/list`).then(setLineFileCollection);
    fetcher(`${BACKEND_API}/api/point/file/list`).then(setPointFileCollection);
    fetcher(`${BACKEND_API}/api/sensor/project/list`).then(setSensorCollection);

    const fetchAndSetLayerData = (url, setLayerFileIdx, setLayerCounter, setHiddenDataList, shapefileIdKey) => {
      fetcher(url).then((data) => {
        const layerFileIdxObj = {};
        const layerCounterObj = {};
        const idList = data.map((row) => {
          layerFileIdxObj[row.id] = row[shapefileIdKey];
          layerCounterObj[row[shapefileIdKey]] = 0;
          return row.id;
        });
        setLayerFileIdx(layerFileIdxObj);
        setLayerCounter(layerCounterObj);
        setHiddenDataList(idList);
      });
    };
    
    fetchAndSetLayerData(
      `${BACKEND_API}/api/shp/data/list`,
      setShpLayerFileIdx,
      setShpLayerCounter,
      setShpHiddenDataList,
      'shapefile-id'
    );
    fetchAndSetLayerData(
      `${BACKEND_API}/api/line/data/list`,
      setLineLayerFileIdx,
      setLineLayerCounter,
      setLineHiddenDataList,
      'linefile-id'
    );
    fetchAndSetLayerData(
      `${BACKEND_API}/api/point/data/list`,
      setPointLayerFileIdx,
      setPointLayerCounter,
      setPointHiddenDataList,
      'pointfile-id'
    );
    
  }, []);

  const [shpSelected, setShpSelected] = React.useState([]);
  const [shpShow, setShpShow] = React.useState(true);
  const [shpHiddenDataList, setShpHiddenDataList] = React.useState([]);
  const [lineHiddenDataList, setLineHiddenDataList] = React.useState([]);
  const [pointHiddenDataList, setPointHiddenDataList] = React.useState([]);
  const [pointDataSelected, setPointDataSelected] = React.useState([]);
  const [pointShow, setPointShow] = React.useState(true);
  const [lineDataSelected, setLineDataSelected] = React.useState([]);
  const [lineShow, setLineShow] = React.useState(true);
  const [sensorSelected, setSensorSelected] = React.useState([]);
  const [sensorShow, setSensorShow] = React.useState(true);


  const onDelete = () => {
    window.location.reload();
  };

  return (
    <Row>
      <Col xl="3" md="12">
        <Button
          className="btn-fw"
          variant={
            !shpShow || !lineShow|| !pointShow || !sensorShow ? "primary" : "default"
          }
          onClick={() => {
            setShpShow(true);
            setPointShow(true);
            setLineShow(true);
            setSensorShow(true);
          }}
        >
          <i className="nc-icon nc-globe-2" style={{ marginRight: "10px" }}></i>
          {i18n.strings.dashboard.arcGisViewer.all}
        </Button>
        <br></br>
        <LayerSelector
          id={1}
          show={shpShow}
          icon="nc-icon nc-puzzle-10"
          title={i18n.strings.dashboard.arcGisViewer.shapeFile}
          backgroundColor="primary"
          options={shpCollection}
          onSwitch={() => setShpShow(!shpShow)}
          onSelect={setShpSelected}
          childrenList={shpDataCollection}
          hiddenChildrenList={shpHiddenDataList}
          keyValue="shapefile-id"
          fileType="shp"
          parentType="file"
          childrenType="data"
          onHide={(id) =>
            setShpHiddenDataList((prevList) => {
              if (prevList.includes(id)) {
                if(shpLayerCounter[shpLayerFileIdx[id]]> layerLimit){
                  pop(
                    i18n.strings.upload.step2.isURLRequired.message,
                    i18n.strings.dashboard.arcGisViewer.layer.limitAlert,
                    true,
                    "danger" 
                  )  
                  return prevList
                }
                prevList.splice(prevList.indexOf(id), 1);
                shpLayerCounter[shpLayerFileIdx[id]] += 1;
                return [...prevList];
              }
              shpLayerCounter[shpLayerFileIdx[id]] -= 1;
              return [...prevList, id];
            })
          }
          onDelete={onDelete}
        /><LayerSelector
        id={6}
        show={lineShow}
        icon="nc-icon nc-puzzle-10"
        title={i18n.strings.dashboard.arcGisViewer.lineData}
        backgroundColor="primary"
        options={lineFileCollection}
        onSwitch={() => setLineShow(!lineShow)}
        onSelect={setLineDataSelected}
        childrenList={lineDataCollection}
        hiddenChildrenList={lineHiddenDataList}
        keyValue="linefile-id"
        fileType="line"
        parentType="file"
        childrenType="data"
        onHide={(id) =>
          setLineHiddenDataList((prevList) => {
            if (prevList.includes(id)) {
              if(lineLayerCounter[lineLayerFileIdx[id]]> layerLimit){
                pop(
                  i18n.strings.upload.step2.isURLRequired.message,
                  i18n.strings.dashboard.arcGisViewer.layer.limitAlert,
                  true,
                  "danger" 
                )  
                return prevList
              }
              prevList.splice(prevList.indexOf(id), 1);
              lineLayerCounter[lineLayerFileIdx[id]] += 1;
              return [...prevList];
            }
            lineLayerCounter[lineLayerFileIdx[id]] -= 1;
            return [...prevList, id];
          })
        }
        onDelete={onDelete}
      />
      <LayerSelector
          id={2}
          show={pointShow}
          icon="nc-icon nc-puzzle-10"
          title={i18n.strings.dashboard.arcGisViewer.pointData}
          backgroundColor="primary"
          options={pointFileCollection}
          onSwitch={() => setPointShow(!pointShow)}
          onSelect={setPointDataSelected}
          childrenList={pointDataCollection}
          hiddenChildrenList={pointHiddenDataList}
          keyValue="pointfile-id"
          fileType="point"
          parentType="file"
          childrenType="data"
          onHide={(id) =>
            setPointHiddenDataList((prevList) => {
              if (prevList.includes(id)) {
                if(pointLayerCounter[pointLayerFileIdx[id]]> layerLimit){
                  pop(
                    i18n.strings.upload.step2.isURLRequired.message,
                    i18n.strings.dashboard.arcGisViewer.layer.limitAlert,
                    true,
                    "danger" 
                  )  
                  return prevList
                }
                prevList.splice(prevList.indexOf(id), 1);
                pointLayerCounter[pointLayerFileIdx[id]] += 1;
                return [...prevList];
              }
              pointLayerCounter[pointLayerFileIdx[id]] -= 1;
              return [...prevList, id];
            })
          }
          onDelete={onDelete}
        />
        <LayerSelector
          id={3}
          show={sensorShow}
          icon="nc-icon nc-istanbul"
          title={i18n.strings.dashboard.arcGisViewer.sensor}
          backgroundColor="primary"
          options={sensorCollection}
          onSwitch={() => setSensorShow(!sensorShow)}
          onSelect={setSensorSelected}
          fileType="sensor"
          parentType="project"
          childrenType="instance"
          onDelete={onDelete}
        /></Col>
        <Col xl="9" md="12">
        <WebMap
          mapProperties={{ basemap: "osm" }}
          viewProperties={{
            center: [121.53897799930016, 25.017766628321343],
            zoom: 16,
            popup: {
              defaultPopupTemplateEnabled: true,
            },
          }}
          style={{
            position: "relative",
            height: "80vh",
          }}
        >
          <Setting />
          <SavePlugin
            excludeDataList={shpDataCollection.filter(
              (elem) => !shpHiddenDataList.includes(elem.id) && !elem.downloadable
            )}
          />
          <Plugin />
          <LayerRenderer
            type='shape'
            collection={shpCollection}
            dataListCollection={shpDataCollection}
            hiddenDataList={shpHiddenDataList}
            selected={shpSelected}
            show={shpShow}
            LayerComponent={ShapeLayer}
          />
          <LayerRenderer
            type='line'
            collection={lineFileCollection}
            dataListCollection={lineDataCollection}
            hiddenDataList={lineHiddenDataList}
            selected={lineDataSelected}
            show={lineShow}
            LayerComponent={LineLayer}
          />
          <LayerRenderer
            type='point'
            collection={pointFileCollection}
            dataListCollection={pointDataCollection}
            hiddenDataList={pointHiddenDataList}
            selected={pointDataSelected}
            show={pointShow}
            LayerComponent={PointLayer}
          />
          {sensorCollection ? (
            sensorCollection.map((data, index) => (
              <SensorLayer
                key={index}
                disabled={
                  sensorSelected.findIndex((elem) => elem.value === data.id) ===
                    -1 || !sensorShow
                }
                info={data}
                color={pickColor(index)}
              />
            ))
          ) : (
            <></>
          )}
        </WebMap>
      </Col>
    </Row>
  );
};

export default ArcGISViewer;

