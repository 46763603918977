import React from "react";
import { Link } from "react-router-dom";

// react-bootstrap components
import { Button, Card, Container, Row, Col } from "react-bootstrap";

// arcgis viewer
import File3DApprovalTable from "./Tables/File3DApprovalTable";

import { useI18n } from "../context/i18n";

function file3dApproval() {
  const { i18n } = useI18n();

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">{i18n.strings.file3DApproval.title}</Card.Title>
              </Card.Header>
              <Card.Body>
                <File3DApprovalTable />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default file3dApproval;
