import React from "react";
import { Button, Row, Col, Form, FormGroup, FormLabel } from "react-bootstrap";
import { makeStyles, Tooltip } from "@material-ui/core";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { useAuth } from "context/Auth";
import { useI18n } from "context/i18n";
import Cookies from "universal-cookie";
import { BACKEND_API } from "config";
import { useAlert } from "context/Alert";


const cookies = new Cookies();
const axios = require("axios").default;


const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: 500,
  },
}));

const File3DReviseForm = ({
  row = "",
}) => {
  const classes = useStyles();
  const { info } = useAuth();
  const { i18n } = useI18n();
  const [select , setSelect] = React.useState('true');
  const [layerURL, setLayerURL] = React.useState(null);
  const [reply, setReply] = React.useState(null);
  const { pop } = useAlert();
  const [reviewedData, setReviewedData] = React.useState([]);

  const handleUploadClick = () => {
    if (!select|| !reply ) return;

    const yes = confirm(i18n.strings.upload.step2.upload.alert);
    if (!yes) return;
    if(select == 'true'){
      if(!validURL(layerURL) || (!layerURL && layerURL.length  != 0) ){
        pop(
          i18n.strings.upload.step2.isURLRequired.message,
          i18n.strings.upload.step2.isURLRequired.URLFormaatMessage,
          true,
          "danger"
        )        
        return;
       }
    }else{
      if( layerURL !== null && layerURL !== ''){
        pop(
          i18n.strings.upload.step2.isURLRequired.message,
          i18n.strings.upload.step2.isURLRequired.URLBlankMessage,
          true,
          "danger"
        );
        return;
      }
    }

    var formData = new FormData();
    formData.append("rowId", reviewedData.id);
    formData.append("layerName", row.name);
    formData.append("select", select);
    formData.append("layerURL", layerURL);
    formData.append("reply", reply);
    upload(() =>
      axios.post(`${BACKEND_API}/api/file3d/layer/update`, formData, {
        headers: {
          Authorization: "Bearer " + cookies.get("sdgs_access_token"),
          "Content-Type": "multipart/form-data",
        },
      })
    );

  };


  const upload = (uploader) => {
    pop(
        i18n.strings.upload.alert.title,
        i18n.strings.upload.alert.upload.title,
        false
    );
    
    uploader()
        .then((response) => {
        console.log(response);
        alert( response.data );
        window.location.reload(true);
        //pop(i18n.strings.upload.alert.title, response.data, true, "info");
        })
        .catch((error) => {
        pop(
            i18n.strings.upload.alert.title,
            error.response.data,
            true,
            "danger"
        );
        });
    };

    const fetcher = async(url) =>
    await axios
        .get(url, {
        headers: {
            Authorization: "Bearer " + cookies.get("sdgs_access_token"),
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
            setSelect(res.data.acceptance.toString())
            setLayerURL(res.data.url)
            setReply(res.data.replyText)
            return res.data
        }
    );

    const validURL = (str) => {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    }


React.useEffect(async () => {
    await fetcher(`${BACKEND_API}/api/file3d/layer/getById/${row.id}`)
    .then(setReviewedData)
    }, []);

  return (
    <div className="shape-file-attributes">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
            <FormLabel>
            {i18n.strings.reviewTable3D.reviewForm.review}
              <span className="text-danger">*</span>
            </FormLabel>
            <Form.Control 
            as="select"
            value={select ? select : reviewedData.acceptance.toString()}
            onChange={(e) => {
              setSelect(e.target.value);
            }}
            >
              <option value={true}  >{i18n.strings.reviewTable3D.reviewForm.select.accept}</option>
              <option value={false} >{i18n.strings.reviewTable3D.reviewForm.select.reject}</option>
            </Form.Control> 
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
            <FormLabel>
            {i18n.strings.reviewTable3D.reviewForm.url}
            </FormLabel>
            <Form.Control
              placeholder={'layerURL'}
              type="text"
              value={layerURL}
              onChange={(e) => {
                setLayerURL(e.target.value);
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
            <FormLabel>
            {i18n.strings.reviewTable3D.reviewForm.reply}
              <span className="text-danger">*</span>
            </FormLabel>
            <Form.Control  
              as="textarea" rows={3}
              placeholder={'Reply'}
              type="text"
              value={reply}
              onChange={(e) => {
                setReply(e.target.value);
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col
          md={{ span: 10, offset: 1 }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className="btn-wd"
            variant="primary"
            onClick={handleUploadClick}
          >
           {i18n.strings.reviewTable3D.reviewForm.sent}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default File3DReviseForm;
