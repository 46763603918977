import React from "react";
import { Link } from "react-router-dom";

// react-bootstrap components
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import FileTable from "components/ReactTable/FileTable";
// arcgis viewer
import File3DApprovalTable from "./Tables/File3DApprovalTable";

import { useI18n } from "../context/i18n";
import { useAuth } from "../context/Auth";
import Cookies from "universal-cookie";
import useSWR from "swr";
import { BACKEND_API } from "config";

const axios = require("axios").default;
const cookies = new Cookies();
const fetcher = (url) =>
axios.get(url, {
    headers: {
        Authorization: "Bearer " + cookies.get("sdgs_access_token"),
        "Content-Type": "application/json",
    },
    })
.then((res) => res.data);

function Dashboard1D() {
    const { i18n } = useI18n();
    const { info } = useAuth();


    const { data: notReviewedData, error : notReviewedDataError } = useSWR(
        `${BACKEND_API}/api/file1d/file/get`,
        fetcher,
        { refreshInterval: 6000000 }
        );

    if (!notReviewedData || notReviewedDataError) return <></>;
    

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">{i18n.strings.file1D.title}</Card.Title>
              </Card.Header>
              <Card.Body>
                {notReviewedData.length !== 0 && (
                <FileTable
                 data={notReviewedData}
                 columns={Object.keys(notReviewedData[0]).map((name, index) => {
                   return {
                   id: index,
                   Header: name,
                   accessor: name,
                 }
                 }
                 )}
                 />)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard1D;
