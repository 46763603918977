import React from "react";
import { Button, Row, Col, Form, FormGroup, FormLabel } from "react-bootstrap";
import { makeStyles, Tooltip } from "@material-ui/core";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { useAuth } from "context/Auth";
import { useI18n } from "context/i18n";
import Cookies from "universal-cookie";
import { BACKEND_API } from "config";
import { useAlert } from "context/Alert";

const cookies = new Cookies();
const axios = require("axios").default;




const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: 500,
  },
}));

const File1DReviewForm = ({
  row = "",
}) => {
  const classes = useStyles();
  const { info } = useAuth();
  const { i18n } = useI18n();
  const [select , setSelect] = React.useState('true');
  const [reply, setReply] = React.useState(null);
  const { pop } = useAlert();
  const handleUploadClick = () => {
    if (!select|| !reply ) return;

    const yes = confirm(i18n.strings.upload.step2.upload.alert);
    if (!yes) return;

    var formData = new FormData();
    formData.append("name", row.name);
    formData.append("select", select);
    formData.append("reply", reply);
    formData.append("uploadId", row.id);
    upload(() =>
      axios.post(`${BACKEND_API}/api/file1d/file/review`, formData, {
        headers: {
          Authorization: "Bearer " + cookies.get("sdgs_access_token"),
          "Content-Type": "multipart/form-data",
        },
      })
    );

  };

  const upload = (uploader) => {
    pop(
      i18n.strings.upload.alert.title,
      i18n.strings.upload.alert.upload.title,
      false
    );
  
    uploader()
      .then((response) => {
        console.log(response);
        alert( response.data );
        window.location.reload(true);
        //pop(i18n.strings.upload.alert.title, response.data, true, "info");
      })
      .catch((error) => {
        pop(
          i18n.strings.upload.alert.title,
          error.response.data,
          true,
          "danger"
        );
      });
  };

  return (
    <div className="shape-file-attributes">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
            <FormLabel>
            {i18n.strings.reviewTable3D.reviewForm.review}
              <span className="text-danger">*</span>
            </FormLabel>
            <Form.Control 
            as="select"
            value={select ? select : "true"}
            onChange={(e) => {
              setSelect(e.target.value);
            }}
            >
              <option value={'true'}  >{i18n.strings.reviewTable3D.reviewForm.select.accept}</option>
              <option value={'false'} >{i18n.strings.reviewTable3D.reviewForm.select.reject}</option>
            </Form.Control> 
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
            <FormLabel>
            {i18n.strings.reviewTable3D.reviewForm.reply}
              <span className="text-danger">*</span>
            </FormLabel>
            <Form.Control  
              as="textarea" rows={3}
              placeholder={'Reply'}
              type="text"
              value={reply ? reply : ""}
              onChange={(e) => {
                setReply(e.target.value);
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col
          md={{ span: 10, offset: 1 }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className="btn-wd"
            variant="primary"
            onClick={handleUploadClick}
          >
           {i18n.strings.reviewTable3D.reviewForm.sent}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default File1DReviewForm;
