import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import File3DReviewForm from "../../../views/Forms/Wizard/Forms/3D/File3DReviewForm";
import { useI18n } from "context/i18n";
function ReviewWindow({
    row = "",
    isAdmin,
    isReviewed,
    isMainRow
}){

    if(!isAdmin || isReviewed) return  <></>
    if(row.uploadType == 're-upload') return  <></>
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { i18n } = useI18n();
  
    return (
      <td>
        <Button variant="primary" size="sm" onClick={handleShow}>
          {i18n.strings.reviewTable3D.reviewWindow.reviewBtn}
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.strings.reviewTable3D.reviewWindow.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <File3DReviewForm
                row = {row}
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
            {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>
      </td>
    );
  }

export default ReviewWindow;