import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import File3DReviseForm from "../../../views/Forms/Wizard/Forms/3D/File3DReviseForm";
import { useI18n } from "context/i18n";
function ReviseWindow({
    row = "",
    isAdmin,
    isReviewed,
    isMainRow
}){
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { i18n } = useI18n();

    if(!isAdmin) return  <></>

    if(!isReviewed){
      if(row.uploadType != 're-upload') return  <></>
    }




    return (
      <td>
        <Button variant="warning" size="sm" onClick={handleShow}>
          {i18n.strings.reviewTable3D.reviseWindow.reviseBtn}
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.strings.reviewTable3D.reviseWindow.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <File3DReviseForm
                row = {row}
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
            {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>
        </td>
    );
  }

export default ReviseWindow;