import React from 'react';
import "../../assets/css/file-display.css";
import { useI18n } from "../../context/i18n";

export function  FileDisplay(value){
  // 使用 "_" 切割输入字符串
  const { i18n } = useI18n();
  const {input, isSelector, date } = value;
  const parts = (input.indexOf('__') > -1)? input.split('__'):input.split('_');
  const fileName = parts[1]; // 第二部分是文件名
  const ownerInfo = (isSelector === true)? '' :`${i18n.strings.fileTable.provider.title} ${parts[0]} \n
  ${date}`; // 第一部分是擁有者信息

  return (
    <div className="file-container">
      <span className="file-name">{fileName}</span>
      <span className="owner-info">{ownerInfo}</span>
    </div>
  );
};

