import React from "react";
import { useLocation } from "react-router-dom";
// react-bootstrap components
import { Card, Container, Row, Col,Tabs ,Tab } from "react-bootstrap";
// core components
import ReviewTable1D, {StatusPill } from "components/ReactTable/ReviewTable1D";
import { useI18n } from "../../context/i18n";
import { useAuth } from "../../context/Auth";
import Cookies from "universal-cookie";
import useSWR from "swr";
import { BACKEND_API } from "config";
const cookies = new Cookies();
const axios = require("axios").default;
const fetcher = (url) =>
  axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + cookies.get("sdgs_access_token"),
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);

// const RemoveReviewingData = (data) => {
//   let output  = new Array();
//   data.forEach(row => {
//     if(row.isReviewing == 0){
//       output.push(row)
//     }
//   });
  
//   return output
// }
function File1DApprovalTable(){
    const { i18n } = useI18n();
    const { info } = useAuth();
    // hancdle url params
    let loation = useLocation();

    const { data: notReviewedData, error : notReviewedDataError } = useSWR(
    `${BACKEND_API}/api/file1d/data/review`,
    fetcher,
    { refreshInterval: 6000000 }
    );
    let { data: ReviewedData, error : ReviewedDataError } = useSWR(
      `${BACKEND_API}/api/file1d/data/reviewed`,
      fetcher,
      { refreshInterval: 6000000 }
      );

    if (!notReviewedData || notReviewedDataError) return <></>;
    if (!ReviewedData || ReviewedDataError) return <></>;
    // ReviewedData = RemoveReviewingData(ReviewedData);
    return (
        <>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title={i18n.strings.reviewTable3D.reviewPanel.notReviewed}>
                      <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    {
                      notReviewedData.length !== 0 && (
                        <ReviewTable1D
                          data={notReviewedData}
                          columns={Object.keys(notReviewedData[0]).map((name, index) => {
                            if(name == "uploadType"){
                              return  {
                                id: index,
                                Header: name,
                                accessor: name,
                                Cell: StatusPill, // n
                              }
                            }
                            return {
                            id: index,
                            Header: name,
                            accessor: name,
                          }
                          }
                          )}
                          isAdmin={info.role == "admin"}
                          isReviewed={false}
                        />
                      )
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          </Tab>
          <Tab eventKey="profile" title={i18n.strings.reviewTable3D.reviewPanel.Reviewed}>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    {
                      ReviewedData.length !== 0 && (
                        <ReviewTable1D
                          data={ReviewedData}
                          columns={Object.keys(ReviewedData[0]).filter(function(name) {
                            const hidedCol = ["replyText"]
                            if (hidedCol.includes(name)){
                              return false; // skip
                            }
                            return true;
                          }).map((name, index) => {
  
                            if(name == "acceptance"){
                              return  {
                                id: name,
                                Header: name,
                                accessor: d => d.acceptance,
                                Cell: StatusPill, // new
                              }
                            }
                            if(name == "uploadType"){
                              return  {
                                id: index,
                                Header: name,
                                accessor: name,
                                Cell: StatusPill, // n
                              }
                            }
                            return {
                            id: index,
                            Header: name,
                            accessor: name,
                          }
                        }
                          )
                        }
                          isAdmin={info.role == "admin"}
                          isReviewed={true}
                        />
                      )
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          </Tab>
        </Tabs>
        <br></br>
        </>
      );
}

export default File1DApprovalTable;