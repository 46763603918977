import { Button } from "react-bootstrap";
import { useAuth } from "context/Auth";
import { useI18n } from "context/i18n";
import { useAlert } from "context/Alert";
import Cookies from "universal-cookie";
import { BACKEND_API } from "config";


export default function setLayerVersionBtn(data){
    const { info } = useAuth();
    const { i18n } = useI18n();
    const { pop } = useAlert();
    const axios = require("axios").default;
    const cookies = new Cookies();
    var formData = new FormData();
    const upload = (uploader) => {
        const yes = confirm(i18n.strings.reviewTable3D.setLayerVersionBtn.send.alert);
        if (!yes) return;

        pop(
          i18n.strings.reviewTable3D.setLayerVersionBtn.sending.title,
          i18n.strings.reviewTable3D.setLayerVersionBtn.sending.message,
          false
        );
    
        uploader()
          .then((response) => {
            console.log(response);
            pop(i18n.strings.upload.alert.title, response.data, true, "info");
            setTimeout(function () {
              window.location.reload(true);
            }, 2000);
          })
          .catch((error) => {
            pop(
              i18n.strings.upload.alert.title,
              i18n.strings.upload.alert.failed,
              true,
              "danger"
            );
          });
      };

    const handleUploadClick = () => {
      upload(() =>
      axios.post(`${BACKEND_API}/api/file3d/layer/setShowingLayer/${row.id}/${row.name}`, formData,{
        headers: {
          Authorization: "Bearer " + cookies.get("sdgs_access_token"),
          "Content-Type": "multipart/form-data",
        },
      })
      );
    };

    const {row,isAdmin,isReviewed,isMainRow}  = data;
    
    if(isMainRow || !isReviewed) return <></>;
    if(row.acceptance == false) return  <></>
  
    return <td><Button
            className="btn-wd"
            variant="primary"
            size="sm"
            onClick={handleUploadClick}
          >
            {i18n.strings.reviewTable3D.setLayerVersionBtn.btnTitle}
          </Button></td>
  }