import React from "react";
import { Button, Card, Form, Nav, Container, Col, Image } from "react-bootstrap";
import { useAuth } from "../../context/Auth";
import { useI18n } from "../../context/i18n";
import { Row } from "reactstrap";

function HomePage(){
    const { i18n } = useI18n();
    
    return    <>
    <div
    //   className="full-page section-image"
      data-color="black"
    //   data-image={require("assets/img/full-screen-image-2.jpg").default}
    >
      <div className="content d-flex align-items-center p-0">
        <Container>
          <Row>
          <Col className="mx-auto" lg="4" md="8">
            <Card 
              bg="secondary"
              text="white"
            >
              <Card.Body>
              <Image src={require("assets/img/1d_preview.png").default} fluid></Image>
              <p></p>
                <Card.Text>
                <h3>
                  {i18n.strings.homepage.d1.title}
                  </h3>
                  <p></p>
                  {i18n.strings.homepage.d1.description}
                  <p><a href="https://sites.google.com/ntubim.net/ntusdgscampus/home?fbclid=IwAR3FIx5b2OA71bODOOvyDvmBsyGbvqVw5vTLdGZKLCywY6hmrzBeSuXNHbE">
                    NTU SDGs Campus
                    </a></p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mx-auto" lg="4" md="8">
            <Card 
              bg="secondary"
              text="white"
            >
              <Card.Body>
              <Image src={require("assets/img/2d_preview.png").default} fluid></Image>
              <p></p>
                <Card.Text>
                <h3>
                  {i18n.strings.homepage.d2.title}
                  </h3>
                  <p></p>
                  {i18n.strings.homepage.d2.description}
                  <p><a href="https://sites.google.com/ntubim.net/ntusdgscampus/home?fbclid=IwAR3FIx5b2OA71bODOOvyDvmBsyGbvqVw5vTLdGZKLCywY6hmrzBeSuXNHbE">
                    NTU SDGs Campus
                    </a></p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mx-auto" lg="4" md="8">
            <Card 
              bg="secondary"
              text="white"
            >
              <Card.Body>
              <Image src={require("assets/img/3d_preview.png").default} fluid></Image>
              <p></p>
                <Card.Text>
                <h3>
                  {i18n.strings.homepage.d3.title}
                  </h3>
                  <p></p>
                  {i18n.strings.homepage.d3.description}
                  <p><a href="https://sites.google.com/ntubim.net/ntusdgscampus/home?fbclid=IwAR3FIx5b2OA71bODOOvyDvmBsyGbvqVw5vTLdGZKLCywY6hmrzBeSuXNHbE">
                    NTU SDGs Campus
                    </a></p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          </Row>
        </Container>
      </div>
      <div
        className="full-page-background"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/full-screen-image-2.jpg").default +
            ")",
        }}
      ></div>
    </div>
  </>
}
export default HomePage;