import { useState, useEffect } from "react";
import { loadModules } from "esri-loader";
import { v4 as uuidv4 } from "uuid";

import Cookies from "universal-cookie";
import { BACKEND_API } from "config";
const cookies = new Cookies();
const axios = require("axios").default;

const fetcher = (url) =>
  axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + cookies.get("sdgs_access_token"),
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);

const ShapeLayer3D = ({
  map,
  disabled,
  info,
  dataList,
  hiddenDataList,
  specificVersion = false,
  color = "orange",
  ...rest
}) => {
  const [myFeatureLayers, setMyFeatureLayers] = useState([]);

  useEffect(() => {
    return function cleanup() {
      map.removeMany(myFeatureLayers);
    };
  }, []);

  useEffect(() => {
    if (disabled) {
      map.removeMany(myFeatureLayers);
      return;
    }

    if (myFeatureLayers.length > 0) map.removeMany(myFeatureLayers);


    loadModules([
      "esri/layers/FeatureLayer",
      "esri/Graphic",
      "esri/layers/support/Field",
      "esri/layers/SceneLayer",
      "esri/layers/BuildingSceneLayer",
    ])
      .then(async ([FeatureLayer, Graphic, Field,SceneLayer,BuildingSceneLayer]) => {
        var sourceGraphics = [];
        const renderer = {
          type: "simple", // autocasts as new SimpleRenderer()
          symbol: {
            type: "simple-fill", // autocasts as new SimpleMarkerSymbol()
            size: 10,
            color: color,
            outline: {
              // autocasts as new SimpleLineSymbol()
              width: 0.5,
              color: "white",
            },
          },
        };
        let sliceWidget = null;
        let doorsLayer = null;
        let sliceTiltEnabled = true;
        const excludedLayers = [];
        info.url;
        var layer3d;

        if(info.url.search('tiles') != -1){
          layer3d = new BuildingSceneLayer({
            url: info.url
          })
        }else{
          layer3d = new SceneLayer({
            url: info.url
          })
        }
        map.layers.add(layer3d);

        if(info.url.search('tiles') != -1){
          layer3d.when(
            () => {
              // Iterate through the flat array of sublayers and extract the ones
              // that should be excluded from the slice widget
              layer3d.allSublayers.forEach((layer) => {
                // modelName is standard accross all BuildingSceneLayer,
                // use it to identify a certain layer
                switch (layer.modelName) {
                  // Because of performance reasons, the Full Model view is
                  // by default set to false. In this scene the Full Model should be visible.
                  // case "FullModel":
                  //   layer.visible = true;
                  //   break;
                  // case "Overview":
                  // case "Rooms":
                  //   layer.visible = false;
                  //   break;
                  // // Extract the layers that should not be hidden by the slice widget
                  // case "Doors":
                  //   doorsLayer = layer;
                  //   excludedLayers.push(layer);
                  //   break;
                  default:
                    layer.visible = true;
                }
              });  
          }); 
        }

        setMyFeatureLayers([layer3d]);
      })
      .catch((err) => console.error(err));
      
  }, [disabled, hiddenDataList]);

  return null;
};

export default ShapeLayer3D;
