
import React from "react";
import {
  useTable,
  useExpanded 
} from "react-table";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'; 
import { useI18n } from "../../context/i18n";
import { useAlert } from "context/Alert";
import { BACKEND_API } from "config";
import Cookies from "universal-cookie";
// import { FileDisplay } from "../Other/FileDisplay";
// import DocViewer, { DocViewerRenderers }  from "react-doc-viewer";
const axios = require("axios").default;

const cookies = new Cookies();

function FileTable({data, columns}){
    const { i18n } = useI18n();
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow  ,state: { expanded }, } =
        useTable({ columns, data },useExpanded);
    const docs = [
          { uri: require("../../assets/img/bg5.jpg") }, // Local File
        ];
    return (
    <>
      <div className="">
        <Table {...getTableProps()} >
            <thead >
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                    return <th {...column.getHeaderProps()} >{column.render("Header")}</th>
                })}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
                prepareRow(row);
                return (
                <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()} >{cell.render("Cell")}</td>;
                    })}
                    <File1DDownloadLink data = {row.original.name}/>
                    <File1DDeleteBtn data= {row.original.name}/>
                </tr>
                );
            })}
            </tbody>
        </Table>
        {/* <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={docs}
        />; */}
      </div>
    </>
    )
}



export default FileTable;



export function File1DDownloadLink(value){
  const {data } = value;
  const { i18n } = useI18n();
  const { pop } = useAlert();
  console.log(data)
  return (<><td>
    <Button
        onClick={() => {
          axios
            .get(
              `${BACKEND_API}/api/file1d/file/download/${data}`,
              {
                headers: {
                  Authorization:
                    "Bearer " + cookies.get("sdgs_access_token"),
                },
                responseType: "blob",
              }
            )
            .then((res) => {
              saveAs(res.data, data);
            }).catch((res) => {
              console.log(res)
              pop(
                i18n.strings.upload.alert.title,
                i18n.strings.fileTable.download.notAuthorized,
                true,
                "danger"
              );
            });;
        }}
        variant="info"
        size="sm"
        className="text-info btn-link like"
      >
        <i className="fa fa-download" />
      </Button> 
      </td>
      </>
      )
}


export function File1DDeleteBtn(value){
  const {data } = value;
  const { i18n } = useI18n();
  const { pop } = useAlert();
  return (<td>
    <Button
        onClick={() => {
          const yes = confirm(i18n.strings.fileTable.deleteButton.alert.comfirm);
          if (!yes) return;
          axios
            .get(
              `${BACKEND_API}/api/file1d/file/delete/${data}`,
              {
                headers: {
                  Authorization:
                    "Bearer " + cookies.get("sdgs_access_token"),
                },
                responseType: "blob",
              }
            )
            .then((response) => {
              console.log(response);
              pop(i18n.strings.fileTable.deleteButton.alert.title, i18n.strings.fileTable.deleteButton.alert.success, true, "info");
              setTimeout(function () {
                window.location.reload(true);
              }, 2000);
            }).catch((error) => {
              pop(
                i18n.strings.fileTable.deleteButton.alert.title,
                i18n.strings.fileTable.deleteButton.alert.failed,
                true,
                "danger"
              );
            });;
        }}
        variant="outline-light active"
        // size="sm"
      >
        {i18n.strings.fileTable.deleteButton.title}
      </Button></td>
  )

}

export function StatusPill({ value }) {
  value += '';
  const status = value ? value : "unknown";

  return (
    <span
      class={classNames(
        "",
        status.startsWith("upload") ? "badge badge-pill badge-primary" : null,
        status.startsWith("re-upload") ? "badge badge-pill bg-warning" : null,
        status.startsWith("update") ? "badge badge-pill bg-success" : null
      )}
    >
      {status}
    </span>
  );
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


