import React from "react";
import { useLocation } from "react-router-dom";
// react-bootstrap components
import { Card, Container, Row, Col,Tabs ,Tab } from "react-bootstrap";
// core components
import ReviewTable3D, {StatusPill } from "components/ReactTable/ReviewTable3D";
import { useI18n } from "../../context/i18n";
import { useAuth } from "../../context/Auth";
import Cookies from "universal-cookie";
import useSWR from "swr";
import { BACKEND_API } from "config";
const cookies = new Cookies();
const axios = require("axios").default;
const fetcher = (url) =>
  axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + cookies.get("sdgs_access_token"),
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);

const AddSubRow = (data) => {
  let layers = new Set();
  data.forEach(row => {
    layers.add(row.name)
  });
  let outputRows = new Array();
  layers.forEach(layer => {
    let layerCollection= [];
    let layerShow = []
    // let maxVersion = -1;
    // data.forEach(row => {
    //   if(row.name == layer && row.version > maxVersion){
    //     layerShow = row
    //     maxVersion = row.version
    //     layerShow['isMainRow'] = true
    //   }
    // });
    data.forEach(row => {
      if(row.name == layer && row.version == row.selectedVersion){
        layerShow = row
        layerShow['isMainRow'] = true
      }
      if(row.name == layer && row.version != row.selectedVersion){
        let subRow = row
        subRow['isMainRow'] = false
        layerCollection.push(subRow)
      }
    });
    if(layerShow.length == 0){
      data.forEach(row => {
        if(row.name == layer){
          layerShow = row
          layerShow['isMainRow'] = true
        }
      });
      outputRows.push(layerShow)
    }else{
      layerShow['subRows'] = layerCollection;
      outputRows.push(layerShow)
    }
  });
  return outputRows
}
const RemoveReviewingData = (data) => {
  let output  = new Array();
  data.forEach(row => {
    if(row.isReviewing == 0){
      output.push(row)
    }
  });
  
  return output
}
function File3DApprovalTable(){
    const { i18n } = useI18n();
    const { info } = useAuth();
    const [fetchSwitch, toggleFetchSwitch] = React.useState(true);
    const [notReviewedData, setNotReviewedData] = React.useState([]);
    const [ReviewedData, setReviewedData] = React.useState([]);
    // hancdle url params
    let loation = useLocation();
    // const { data: notReviewedData, error : notReviewedDataError } = useSWR(
    //   fetchSwitch ? `${BACKEND_API}/api/file3d/data/review/${false}` : null,
    // fetcher,
    // { refreshInterval: 60000000 },
    // );
    // let { data: ReviewedData, error : ReviewedDataError } = useSWR(
    //   fetchSwitch ? `${BACKEND_API}/api/file3d/data/reviewed` : null,
    //   fetcher,
    //   { refreshInterval: 60000000 }
    //   );

        
    React.useEffect(async () => {
      await fetcher(`${BACKEND_API}/api/file3d/data/review/${false}`)
      .then(setNotReviewedData)
      }, []);
    React.useEffect(async () => {
      await fetcher(`${BACKEND_API}/api/file3d/data/reviewed`)
      .then(setReviewedData)
      }, []);

    // if (!notReviewedData || notReviewedDataError) return <></>;
    // if (!ReviewedData || ReviewedDataError) return <></>;
    if (!notReviewedData ) return <></>;
    if (!ReviewedData) return <></>;
    let ReviewedDataX = AddSubRow(ReviewedData);
    ReviewedDataX = RemoveReviewingData(ReviewedDataX);
    return (
        <>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          {/* <input type="file" /> */}
          <Tab eventKey="home" title={i18n.strings.reviewTable3D.reviewPanel.notReviewed}>
                      <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    {
                      notReviewedData.length !== 0 && (
                        <ReviewTable3D
                          data={notReviewedData}
                          columns={Object.keys(notReviewedData[0]).map((name, index) => {
                            if(name == "uploadType"){
                              return  {
                                id: index,
                                Header: name,
                                accessor: name,
                                Cell: StatusPill, // n
                              }
                            }
                            return {
                            id: index,
                            Header: name,
                            accessor: name,
                          }
                          }
                          )}
                          isAdmin={info.role == "admin"}
                          isReviewed={false}
                        />
                      )
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          </Tab>
          <Tab eventKey="profile" title={i18n.strings.reviewTable3D.reviewPanel.Reviewed}>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    {
                      ReviewedDataX.length !== 0 && (
                        <ReviewTable3D
                          data={ReviewedDataX}
                          columns={Object.keys(ReviewedDataX[0]).filter(function(name) {
                            const hidedCol = ['isReviewing',"isMainRow","isMainRow","subRows","selectedVersion","maxVersion"]
                            if (hidedCol.includes(name)){
                              return false; // skip
                            }
                            return true;
                          }).map((name, index) => {
  
                            if(name == "acceptance"){
                              return  {
                                id: name,
                                Header: name,
                                accessor: d => d.acceptance,
                                Cell: StatusPill, // new
                              }
                            }
                            if(name == "uploadType"){
                              return  {
                                id: index,
                                Header: name,
                                accessor: name,
                                Cell: StatusPill, // n
                              }
                            }
                            return {
                            id: index,
                            Header: name,
                            accessor: name,
                          }
                        }
                          )
                          .concat([ {
                            // Build our expander column
                            id: 'expander', // Make sure it has an ID
                            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                              <span {...getToggleAllRowsExpandedProps()}>
                                {isAllRowsExpanded ? "△" : "▼"}
                              </span>
                            ),
                            Cell: ({ row }) =>
                              row.canExpand ? (
                                <span
                                  {...row.getToggleRowExpandedProps({
                                    style: {
                                      paddingLeft: `${row.depth * 10}rem`,
                                    },
                                  })}
                                >
                                  {row.isExpanded ? '△' : '▼'}
                                </span>
                              ) : null,
                          }])
                        }
                          isAdmin={info.role == "admin"}
                          isReviewed={true}
                        />
                      )
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          </Tab>
        </Tabs>
        <br></br>

        </>
      );
}

export default File3DApprovalTable;