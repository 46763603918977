import { useState } from 'react';
import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useI18n } from "context/i18n";
import { BACKEND_API } from "config";
import Cookies from "universal-cookie";

const axios = require("axios").default;
const cookies = new Cookies();

function DescriptionWindow({
    row = "",
    isAdmin,
    isReviewed,
    isMainRow
}){

    if(isAdmin || !isReviewed) return  <></>

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { i18n } = useI18n();
    const [reviewedData, setReviewedData] = React.useState([]);

    const fetcher = async(url) =>
    await axios
        .get(url, {
        headers: {
            Authorization: "Bearer " + cookies.get("sdgs_access_token"),
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
            return res.data
        }
    );

    React.useEffect(async () => {
        await fetcher(`${BACKEND_API}/api/file3d/layer/getById/${row.id}`)
        .then(setReviewedData)
        }, []);

    
  
    return (
      <><td>
        <Button variant="warning" size="sm" onClick={handleShow}>
            Description
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
          Description
          </Modal.Header>
          <Modal.Body>
            {reviewedData.replyText}
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
            {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>
        </td></>
    );
  }

export default DescriptionWindow;