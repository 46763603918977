import { useState } from 'react';
import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useI18n } from "context/i18n";
import { BACKEND_API } from "config";
import Cookies from "universal-cookie";

const axios = require("axios").default;
const cookies = new Cookies();

function ReplyText1DWindow({
    row = "",
    isAdmin,
    isReviewed
}){

    if(!isReviewed) return  <></>

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { i18n } = useI18n();  
  
    return (
      <><td>
        <Button variant="warning" size="sm" onClick={handleShow}>
            ReplyText
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            ReplyText
          </Modal.Header>
          <Modal.Body>
            {row.replyText}
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
            {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>
        </td></>
    );
  }

export default ReplyText1DWindow;