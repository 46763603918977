import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import File3DReviewForm from "../../../views/Forms/Wizard/Forms/3D/File3DReviewForm";
import { useI18n } from "context/i18n";
import Reupload3DForm from "../../../views/Forms/Wizard/Forms/3D/Reupload3DForm.js";
function ReuploadWindow({
    row = "",
    isAdmin,
    isReviewed,
    isMainRow
}){
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { i18n } = useI18n();

    if(!isReviewed) return  <></>
    if(row.acceptance == true) return  <></>
    if(row.maxVersion != row.version) return  <></>
  
    return (
      <td>
        <Button variant="primary" size="sm" onClick={handleShow}>
          {i18n.strings.reviewTable3D.reuploadWindow.reuploadBtn}
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.strings.reviewTable3D.reuploadWindow.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <File3DReviewForm
                row = {row}
            /> */}
            <Reupload3DForm
                row = {row}            
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
            {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>
        </td>
    );
  }

export default ReuploadWindow;