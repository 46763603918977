import React from "react";
import { Row, Col, Form, FormGroup, FormLabel } from "react-bootstrap";
import Select from "react-select";
import { useAuth } from "context/Auth";
import { useI18n } from "context/i18n";
import { useAlert } from "context/Alert";

import UpdateFile3DForm from "./UpdateFile3DForm";


const Update3DForm = React.forwardRef((props, ref) => {
  const { info } = useAuth();
  const { i18n } = useI18n();
  const { pop } = useAlert();
  props;
  const upload = (uploader) => {
    pop(
      i18n.strings.upload.alert.title,
      i18n.strings.upload.alert.upload.title,
      false
    );

    uploader()
      .then((response) => {
        console.log(response);
        pop(i18n.strings.upload.alert.title, response.data, true, "info");
      })
      .catch((error) => {
        pop(
          i18n.strings.upload.alert.title,
          i18n.strings.upload.alert.failed,
          true,
          "danger"
        );
      });
  };

  // Select Options
  const fileTypeSelectOptions =
    info.role === "admin"
      ? [
          {
            value: "shp/file",
            label: i18n.strings.upload.step2.fileType.shapeFile,
            accept: ".zip",
          },
        ]
      : [
          {
            value: "shp/file",
            label: i18n.strings.upload.step2.fileType.shapeFile,
            accept: ".zip",
          },
        ];
  const ppOptions = [
    {
      value: true,
      label: "Public",
    },
    {
      value: false,
      label: "Private",
    },
  ];

  // general
  const [fileTypeSelect, setFileTypeSelect] = React.useState(
    fileTypeSelectOptions[0]
  );
  const [pp, setPP] = React.useState(ppOptions[0]);
  const [downloadType, setDownloadType] = React.useState({
    value: 'All Accounts',
    label: i18n.strings.upload.step2.downloadType.allAccounts,
  });
  const [isDownloadOptDisable, setIsDownloadOptDisable] = React.useState(false);
  const [description, setDescription] = React.useState("");

  const isValidated = () => {
    return true;
  };

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state: {
      fileTypeSelect,
      pp,
      description,
    },
  }));

  return (
    <div className="wizard-step">
      <p className="text-center mt-4">{i18n.strings.upload.step2.subtitle}</p>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
            <FormLabel>
              {i18n.strings.upload.step2.isPublic.title}{" "}
              <span className="text-danger">*</span>
            </FormLabel>
            <Select
              name="ppSelect"
              value={pp}
              options={ppOptions}
              placeholder={i18n.strings.upload.step2.isPublic.placeholder}
              onChange={(value) => {
                setPP(value)
                if(value.value === false){
                  setIsDownloadOptDisable(true)
                }else{
                  setIsDownloadOptDisable(false)
                }
                setDownloadType({
                  value: 'All Accounts',
                  label: i18n.strings.upload.step2.downloadType.allAccounts,
                })
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
            <FormLabel>
              {i18n.strings.upload.step2.downloadType.title}{" "}
              <span className="text-danger">*</span>
            </FormLabel>
            <Select
              name="downloadTypeSelect"
              value={downloadType}
              options={[
                {
                  value: 'All Accounts',
                  label:
                    i18n.strings.upload.step2.downloadType.allAccounts,
                },
                {
                  value: 'Office Only',
                  label:
                    i18n.strings.upload.step2.downloadType
                      .schoolDivisionOnly,
                },
                {
                  value: 'By Request',
                  label:
                    i18n.strings.upload.step2.downloadType
                      .byRequest,
                },
              ]}
              placeholder={
                i18n.strings.upload.step2.downloadType.placeholder
              }
              onChange={(value) => setDownloadType(value)}
              isDisabled={isDownloadOptDisable}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FormGroup>
            <FormLabel>
              {i18n.strings.upload.step2.description.title}{" "}
              <span className="text-danger">*</span>
            </FormLabel>
            <Form.Control
              placeholder={i18n.strings.upload.step2.description.placeholder}
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></Form.Control>
          </FormGroup>
        </Col>
      </Row>
      {fileTypeSelect.value === "shp/file" && (
        <UpdateFile3DForm
          isPublic={pp.value}
          downloadType={downloadType.value}
          description={description}
          upload={upload}
          originalFileName_={props.row.name}
          uploadId={props.row.id}
        />
      )}
    </div>
  );
});

export default Update3DForm;
