import React from 'react';
import { pickColor } from "./color";

const LayerRenderer = ({ map, type, collection, dataListCollection, hiddenDataList, selected, show, LayerComponent }) => {
  if (!collection || !dataListCollection) {
    return null;
  }

  return collection.map((data, index) => (
    <LayerComponent
      map = { map }
      key={index}
      disabled={isLayerDisabled(selected, data.id, show)}
      info={data}
      dataList={dataListCollection.filter(
        (elem) => elem[`${type}file-id`] === data.id
      )}
      hiddenDataList={hiddenDataList}
      color={pickColor(index)}
    />
  ));
};

const isLayerDisabled = (Selected, dataId, show) => {
    return Selected.findIndex((elem) => elem.value === dataId) === -1 || !show;
  };
export default LayerRenderer;
