
import React from "react";
import {
  useTable,
  useExpanded 
} from "react-table";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'; 
import { useI18n } from "../../context/i18n";
import Review1DWindow from "../InfoWindow/1D/Review1DWindow.js";
import ReplyText1DWindow from "../InfoWindow/1D/ReplyText1DWindow";
import { BACKEND_API } from "config";
import Cookies from "universal-cookie";

const axios = require("axios").default;
const cookies = new Cookies();

function ReviewTable1D({data, columns, isAdmin, isReviewed}){
    const { i18n } = useI18n();
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow  , } =
        useTable({ columns, data });

    return (
    <>
      <div className="">
        <Table {...getTableProps()} >
            <thead >
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                    if(column.Header == "name"){
                      column.Header = "fileName"
                    }
                    return <th {...column.getHeaderProps()} >{column.render("Header")}</th>
                })}
                  {isAdmin ? isReviewed ?
                    <><th></th></> : <><th></th><th></th></> 
                  : ""}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
                prepareRow(row);
                return (
                <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()} >{cell.render("Cell")}</td>;
                    })}
                    { TableBtnBuilder(row,isAdmin,isReviewed)}
                </tr>
                );
            })}
            </tbody>
        </Table>
      </div>
    </>
    )
}



export default ReviewTable1D;


export function StatusPill({ value }) {
  value += '';
  const status = value ? value : "unknown";

  return (
    <span
      class={classNames(
        "",
        status.startsWith("upload") ? "badge badge-pill badge-primary" : null,
        status.startsWith("re-upload") ? "badge badge-pill bg-warning" : null,
        status.startsWith("update") ? "badge badge-pill bg-success" : null
      )}
    >
      {status}
    </span>
  );
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function ReviewDownloadButton(data
  ){
  const {row,isAdmin,isReviewed}  = data;
  if(!isAdmin || isReviewed) return  <></>
  return (
    <td>
    <Button
        onClick={() => {
          axios
            .get(
              `${BACKEND_API}/api/file1d/data/download/${row.name}`,
              {
                headers: {
                  Authorization:
                    "Bearer " + cookies.get("sdgs_access_token"),
                },
                responseType: "blob",
              }
            )
            .then((res) => {
              saveAs(res.data, row.name);
            });
        }}
        variant="outline-info"
        size="sm"
        className="text-info btn-link like"
      >
        <i className="fa fa-download" />
      </Button>
    </td>
  )

}




export function TableBtnBuilder(row,isAdmin,isReviewed) {
  return <>
          <Review1DWindow row= {row.original}  isAdmin ={isAdmin} isReviewed = {isReviewed}/>
          <ReplyText1DWindow row = {row.original}  isAdmin ={isAdmin} isReviewed = {isReviewed} />
        </>
}