import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// react-bootstrap components
import { Button, Card, Form, Row, Col, Table } from "react-bootstrap";

import clsx from "clsx";
import {
  makeStyles,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Box,
} from "@material-ui/core";
import * as _ from "lodash";

import { useI18n } from "../../context/i18n";
import { useAuth } from "../../context/Auth";
import { useAlert } from "context/Alert";
import { FileDisplay } from "../../components/Other/FileDisplay";
import { saveAs } from "file-saver";
import Cookies from "universal-cookie";
import { BACKEND_API } from "config";
const cookies = new Cookies();
const axios = require("axios").default;

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "scroll",
  },
  parent: {},
  child: {},
  childTitle: {
    display: "flex",
  },
}));

const DownloadBtn= ({
  parentList,
  childrenList,
  hiddenChildrenList,
  keyValue,
  fileType,
  parentType,
  childrenType,
  onHide,
  onDelete = () => {}
}) => {
  const classes = useStyles();
  const { i18n } = useI18n();
  const { info } = useAuth();
  const { pop } = useAlert();
  console.log(parentList)
  return (
    <Box className={classes.root}>
      <MuiTable>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parentList.map((p, index) => {
            return (
              <>
                <TableRow>
                  <TableCell>
                    <Typography>
                      <FileDisplay input = {p.name}/>
                    </Typography>
                  </TableCell>
                  {p.downloadable && (
                    <>
                      <TableCell>
                        <Button
                          className="btn-social btn-link"
                          variant="github"
                          onClick={() => {
                            axios
                              .get(
                                `${BACKEND_API}/api/${fileType}/${parentType}/download/${p.name}/${p.version}`,
                                {
                                  headers: {
                                    Authorization:
                                      "Bearer " +
                                      cookies.get("sdgs_access_token"),
                                  },
                                  responseType: "blob",
                                }
                              )
                              .then((res) => {
                                saveAs(res.data, p.name);
                              });
                          }}
                        >
                          <i className="fa fa-download"></i>
                        </Button>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </MuiTable>
    </Box>
  );
};

const LayerSelector3D = ({
  id = 1,
  icon = "nc-icon nc-globe-2",
  title = "",
  backgroundColor = "default",
  options = [],
  onSwitch,
  onSelect,
  childrenList = [],
  hiddenChildrenList = [],
  keyValue = "",
  fileType = "file3d",
  parentType = "",
  childrenType = "",
  onHide = () => {},
  onDelete = () => {}
}) => {
  if (fileType === "") return <></>;

  const { i18n } = useI18n();
  const [multipleSelect, setMultipleSelect] = React.useState(null);

  return (
    <div>
    <Card color={backgroundColor}>
      <Card.Header>
        <Row>
          <Col xl="12" md="6">
          </Col>
          <Col className="text-center" xl="12" md="6">
            <i className={icon} style={{ marginRight: "10px" }}></i>
            {title}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md="12">
            <Select
              className="react-select info"
              placeholder={i18n.strings.dashboard.arcGisViewer.placeholder}
              name="multipleSelect"
              isDisabled={false}
              closeMenuOnSelect={false}
              isMulti
              value={multipleSelect}
              onChange={(value) => {
                setMultipleSelect(value);
                onSelect(value ? value : []);
              }}
              options={[
                {
                  value: "",
                  label: i18n.strings.dashboard.arcGisViewer.selectPlaceholder,
                  isDisabled: true,
                },
                ...options.map((o) => ({
                  value: o.id || o.device_id,
                  label: ((o.name.indexOf('__') > -1)? o.name.split('__')[1]:o.name.split('_')[1]) || o.device,
                })),
              ]}
            />
          </Col>
        </Row>
        {multipleSelect && (
          <Row>
            <Col md="12">
              <DownloadBtn
                parentList={options.filter((elem) =>
                  multipleSelect.find((m) => m.value === elem.id)
                )}
                childrenList={childrenList}
                hiddenChildrenList={hiddenChildrenList}
                keyValue={keyValue}
                fileType={fileType}
                parentType={parentType}
                childrenType={childrenType}
                onHide={onHide}
                onDelete={onDelete}
              />
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
    </div>
  );
};

export default LayerSelector3D;
