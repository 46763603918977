import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import File1DReviewForm from "../../../views/Forms/Wizard/Forms/1D/File1DReviewForm";
import { useI18n } from "context/i18n";
function Review1DWindow({
    row = "",
    isAdmin,
    isReviewed,
}){

    if(!isAdmin || isReviewed) return  <></>
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { i18n } = useI18n();
  
    return (
      <td>
        <Button variant="primary" size="sm" onClick={handleShow}>
          {i18n.strings.reviewTable3D.reviewWindow.reviewBtn}
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.strings.reviewTable3D.reviewWindow.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <File1DReviewForm
                row = {row}
            />
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </td>
    );
  }

export default Review1DWindow;